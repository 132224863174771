<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-title>{{ $t('views.procedures.title') }}</ion-title>
      </ion-toolbar>
      <ion-toolbar>
        <ion-searchbar
          v-model="searchText"
          :placeholder="$t('fields.search.label')"
          @ionChange="onSearch"
        ></ion-searchbar>
      </ion-toolbar>
      <ion-progress-bar v-if="loading" type="indeterminate"></ion-progress-bar>
    </ion-header>

    <ion-content fullscreen>
      <ion-refresher slot="fixed" @ionRefresh="onRefresh">
        <ion-refresher-content></ion-refresher-content>
      </ion-refresher>
      <ion-card
        v-for="procedure in procedures"
        :key="procedure.id"
        button
        @click="onSelect(procedure)"
      >
        <ion-card-header>
          <ion-card-title>{{ procedure.name }}</ion-card-title>
        </ion-card-header>
        <ion-card-content>
          <div v-if="procedure.project">
            {{ procedure.project.name }} -
            {{ procedure.project.customer.name }}
          </div>
          <div v-else>
            {{ $t('labels.withoutProject') }}
          </div>
        </ion-card-content>
        <ion-ripple-effect></ion-ripple-effect>
      </ion-card>

      <ODNNoContent v-if="procedures.length === 0 && loading === false" />

      <ion-infinite-scroll
        @ionInfinite="parseMoreData"
        threshold="100px"
        :disabled="disableInfiniteScrolling"
      >
        <ion-infinite-scroll-content
          loading-spinner="bubbles"
          :loading-text="$t('messages.loading')"
        >
        </ion-infinite-scroll-content>
      </ion-infinite-scroll>
    </ion-content>
  </ion-page>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

import { Filesystem, Directory } from '@capacitor/filesystem';

import { Base64 } from 'js-base64';

import {
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonCard,
  IonCardContent,
  IonCardTitle,
  IonCardHeader,
  IonRippleEffect,
  IonRefresher,
  IonRefresherContent,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonSearchbar,
  IonProgressBar,
  toastController,
} from '@ionic/vue';

import ODNNoContent from '@c/odn-no-content.vue';

export default {
  name: 'OfflineProcedures',
  components: {
    IonContent,
    IonHeader,
    IonPage,
    IonTitle,
    IonToolbar,
    IonCard,
    IonCardContent,
    IonCardTitle,
    IonCardHeader,
    IonRippleEffect,
    IonRefresher,
    IonRefresherContent,
    IonInfiniteScroll,
    IonInfiniteScrollContent,
    IonSearchbar,
    IonProgressBar,
    ODNNoContent,
  },
  data() {
    return {
      procedures: [],
      procedureList: [],
      page: 1,
      limit: 10,
      searchText: '',
      disableInfiniteScrolling: true,
      loading: true,
    };
  },
  computed: {
    ...mapGetters('offline', ['getSection']),
  },
  async ionViewDidEnter() {
    await this.loadLocalData();
    await this.parseData(true);
  },
  ionViewDidLeave() {
    this.procedures = [];
    this.procedureList = [];
    this.page = 1;
    this.searchText = '';
    this.disableInfiniteScrolling = true;
    this.loading = true;
  },
  methods: {
    ...mapActions('procedures', ['selectProcedure']),
    async loadLocalData() {
      this.loading = true;
      try {
        const rawData = await Filesystem.readFile({
          path: 'procedures.json',
          directory: Directory.Data,
        });

        this.procedureList = JSON.parse(Base64.decode(rawData.data));
      } catch (err) {
        const toast = await toastController.create({
          message: this.$t('messages.procedures.get.error'),
          color: 'danger',
          duration: 2000,
        });
        return toast.present();
      } finally {
        this.loading = false;
      }
    },
    async parseData(clear = false) {
      if (clear) {
        this.page = 0;
        this.procedures = [];
      }

      this.procedures = this.procedures.concat(
        this.procedureList
          .filter((a) => {
            const search = this.searchText.toLowerCase();
            return a.name.toLowerCase().indexOf(search) > -1;
          })
          .slice(this.page * this.limit, this.page * this.limit + this.limit)
      );

      if (this.procedures.length < this.procedureList.length) {
        this.disableInfiniteScrolling = false;
      } else {
        this.disableInfiniteScrolling = true;
      }
    },
    async parseMoreData(event) {
      this.page++;
      await this.parseData();
      event.target.complete();
    },
    async onSearch() {
      await this.parseData(true);
    },
    async onRefresh(event) {
      await this.parseData(true);
      event.target.complete();
    },
    onSelect(procedure) {
      this.selectProcedure(procedure);
      this.$router.push(`/offline/procedures/${procedure.id}`);
    },
  },
};
</script>
